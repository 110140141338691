<template>
  <div>
    <search-field title="条件筛选">
      <el-form :inline="true" size="small" label-width="100px">
        <el-form-item label="变动时间">
          <div class="block">
            <el-date-picker
              clearable
              v-model="search.searchFields.create_time_start"
              placeholder="请选择"
              type="datetime"
              value-format="timestamp"
              style="margin-right:10px"
            ></el-date-picker>
            至
            <el-date-picker
              clearable
              placeholder="请选择"
              v-model="search.searchFields.create_time_end"
              type="datetime"
              value-format="timestamp"
              style="margin-left:10px"
            ></el-date-picker>
          </div>
        </el-form-item>
        <el-form-item label="账户变动状态">
          <el-select clearable v-model="search.searchFields.operation" placeholder="请选择">
            <el-option
              v-for="item in operation_list"
              :key="item.code"
              :label="item.value"
              :value="item.code"
            ></el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <div class="search-button-container">
        <el-button type="primary" size="small" @click="getIntegralList(1)">查询</el-button>
        <el-button size="small"  @click="clearSearch()" plain>重置</el-button>
      </div>
    </search-field>
    <div class="page-content">
      <v-table
        :data="options.data"
        :columns="options.columns"
        :isBackPage="true"
        :totalCount="options.totalCount"
        :defaultcurrentPage="search.searchFields.page"
        @handleCurrentChange="handlePageChange"
        @handleSizeChange="handleSizeChange"
      >
      </v-table>
    </div>
  </div>
  </template>
  <script>
  
  import vTable from '@/components/tools/vtable'
  import searchField from '@/components/tools/searchField'
  import { BAApi } from '@/api'
  import { formatDate } from '@/libs/utils'
  
  export default {
    data() {
      return {
        options: {
          columns: [
            {
              label: 'ba姓名',
              key: 'name'
            },
            {
              label: '变动数量',
              type: 'render',
              render: (h, { row }) => {
                return (
                  <div>
                    <div>{row.operation_msg} {row.integral_amount}</div>
                  </div>
                )
              }
            },
            {
              label: '变动时间',
              key: 'create_time'
            },
          ],
          data: [],
          totalCount: 0,
          loading: false,
        },
        search: {
          searchFields: {
            page: 1,
            count: 20,
            create_time_start: '',
            create_time_end: '',
            operation: '',
          }
        },
        operation_list: [
          {
            code: 0,
            value: '全部'
          },
          {
            code: 1,
            value: '入账'
          },
          {
            code: 2,
            value: '出账'
          }
        ],
        updateParams: {}
      }
    },
  
    methods: {
      // 后端分页
      handlePageChange(page) {
        this.search.searchFields.page = page
        this.getIntegralList()
      },
      handleSizeChange(count) {
        this.search.searchFields.count = count
        this.getIntegralList(1)
      },
      async getIntegralList(page) {
        this.search.searchFields.page = page || this.search.searchFields.page
        let params = {...this.search.searchFields}
        if(this.search.searchFields.create_time_start) {
          params.create_time_start = formatDate(Number(this.search.searchFields.create_time_start), 'YYYY-MM-DD HH:mm:ss').dateString
        }
        if(this.search.searchFields.create_time_end) {
          params.create_time_end = formatDate(Number(this.search.searchFields.create_time_end), 'YYYY-MM-DD HH:mm:ss').dateString
        }
        try {
          let data = (await BAApi.getIntegralList(params)).data
          if (data.code === 0) {
            this.options.data = data.receipt_list
            this.options.totalCount = parseInt(data.total_count) || 0
          } else {
            this.$error(data)
          }
        } catch (err) {
          console.error(err)
        }
      },
      clearSearch() {
        this.search = {
          searchFields: Object.assign({ count: this.search.searchFields.count }, {
            page: 1,
            create_time_start: '',
            create_time_end: '',
            operation: '',
          })
        }
      }
    },
  
    mounted() {
      this.getIntegralList()
    },
    components: {
      vTable, searchField
    }
  }
  </script>
  
  <style lang="less"></style>
  